.paymentProviderSelectorItem {
  padding: 0.5rem;
  border: 1px solid #eee;
  border-radius: 3px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.paymentProviderSelectorItem + .paymentProviderSelectorItem {
  margin-left: 1rem;
}

.paymentProviderSelectorItem:hover {
  border-color: red;
  cursor: pointer;
}

.paymentProviderSelectorItem--selected {
  border-color: red;
}

.paymentProviderSelectorItem__icon {
  border-radius: 3px;
}

.paymentProviderSelectorItem--isLoading {
  opacity: 0.5;
  border-color: #eee;
}
