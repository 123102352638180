.paymentIconsBar {
  padding-top: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentIconsBar__paymentIcons {
  opacity: 0.3;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .paymentIconsBar {
    display: block;
    text-align: center;
  }

  .paymentIconsBar__paymentIcons {
    display: block;
    text-align: center;
  }
}

.paymentIconsBar__image {
  border-radius: 4px;
  display: inline-block;
}

.paymentIconsBar__image + .paymentIconsBar__image {
  margin-left: 0.7rem;
}
