.priceBox {
}

.priceBox__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

.priceBox__item--borderBottom {
  border-bottom: 1px solid #282828;
  margin-bottom: 1rem;
}

.priceBox__price--gutterBottom {
  margin-bottom: 1rem;
}

.priceBox__title {
  font-size: 1.125rem;
}

.priceBox__price {
  font-size: 1.125rem;
  white-space: nowrap;
}

.priceBox__title--strong {
  font-weight: bold;
}

.priceBox__price-extra {
  color: #aaa;
  display: block;
}
