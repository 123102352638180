.checkoutHeader__wrapper {
  margin-bottom: 1rem;
}

.alignCenter {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
}

.alignRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media (min-width: 768px) {
  .checkoutHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.checkoutHeader__content {
  flex: 10 0 210px;
}

.checkoutHeader__headline {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .checkoutHeader__headline {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .checkoutHeader__headline {
    text-align: left;
  }
}

.checkoutHeader__image {
  min-width: 8rem;
  display: none;
}

@media (min-width: 768px) {
  .checkoutHeader__image {
    flex: 1 0 80px;
    display: inherit;
    margin-right: 0.5rem;
  }
}

.checkoutHeader__text {
  display: none;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .checkoutHeader__text {
    display: inherit;
  }
}

.checkoutHeader__edit {
  cursor: pointer;
  text-align: center;
  color: #f05a23;
  font-weight: bold;
}

@media (min-width: 768px) {
  .checkoutHeader__edit {
    flex: 1 0 100px;
    padding: 1rem;
    text-align: right;
  }
}
