.page-header {
  height: 70px;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.15);
}

.page-header__logo {
  height: 40px;
}

.page-header__bar {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.page-header__nav {
  display: flex;
  align-items: center;
}

.page-header__link {
  color: #282828;
  font-size: 1.125rem;
  text-decoration: none;
  font-weight: 700;

  padding: 0.625rem 0.9375rem;
  border-radius: 0;
}

@media (max-width: 768px) {
  .page-header__link {
    color: #ffffff;
    border-bottom: 1px #ffffff38 solid;
    margin: 0 20px;
    padding: 4px 0 3px;
    text-transform: uppercase;
  }
}

.page-header__link--raised {
  background-color: #f05a23;
  color: #ffffff;
  margin: 0.5rem 0.9375rem 0.625rem;
  border-radius: 4px;
}

.page-header__link--icon {
  display: block;
  padding: 0.5rem 0.625rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .page-header__nav-inner {
    height: 0;
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .page-header__nav-inner--open {
    padding-top: 60px;
    height: auto;
    z-index: 99;
    background-color: rgb(0, 0, 0);
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .page-header__mobile {
    z-index: 100;
  }

  .page-header__mobile--open {
    z-index: 100;
    color: white;
  }
}
