.edit-subscription__summary {
  line-height: 1.5;
  font-size: 1.1rem;
  color: #282828;
}

.edit-box {
  margin-bottom: 2rem;
}

.edit-box__label {
  font-size: 1.2rem;
  color: #8b9898;
  margin-bottom: 0.5rem;
}
