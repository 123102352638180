select.select,
select.form-control {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1rem + 5px),
    calc(100% - 15px) calc(1rem + 5px);
  background-size: 5px 5px, 5px 5px, 1px 1.5rem;
  background-repeat: no-repeat;
}

select:disabled {
  cursor: not-allowed;
  color: #aaaaaa;
}

select:disabled:hover {
  background-color: inherit;
}
