@media (min-width: 600px) {
  .app__site {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}

.app__main {
  width: 100%;
  flex: 1 0 auto; /* required for IE11 */
}

.app__content {
  max-width: 1140px;
  margin: 2rem auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 600px) {
  .app__content {
    padding: 2rem;
  }
}
