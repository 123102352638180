.action-dialog {
  padding: 2rem;
}

.action-dialog__title {
  font-family: 'Bariol', 'Arial', sans-serif;
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.action-dialog__content {
  margin-bottom: 1rem;
}

.action-dialog__actions {
  text-align: right;
}
