.checkoutSubmit {
  padding: 1rem;
  background: #eee;
}

@media (min-width: 768px) {
  .checkoutSubmit {
    padding: 2rem;
  }
}

.checkoutSubmit__price {
  margin-top: 0.5rem;
  color: #aaa;
  display: block;
}
