.button {
  font-family: 'Bariol', sans-serif;
  position: relative;
  display: inline-block;
  padding: 0.75rem 1.125rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-decoration: none;
}

.button:disabled {
  box-shadow: none;
  cursor: pointer;
  opacity: 0.5;
}

.button__raised {
  background: #f05a23;
  color: white;
}

.button--border {
  border: 1px solid #c6c6c6;
  background: transparent;
}

.button--fullWidth {
  display: block;
  width: 100%;
}

.button--fullWidthMobile {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .button--fullWidthMobile {
    display: inline-block;
    width: inherit;
  }
}

.button--primary {
  color: #f05a23;
}

.button__flat {
  background-color: transparent;
  font-weight: bold;
}

.button__promo {
  font-size: 1.25rem;
  font-weight: bold;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 700% 0;
  }
}

.button--isLoading {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABQCAYAAACpv3NFAAADGklEQVR4AWIYSPD//39AsXah4ooORWGYcam767i7+/u/1F3XFxDOrp3+wMcKkZKZhF1dmce/j+GPN+3j0v+AVdgKffprsBX69Ndl7Z/09lpSPN/7Imv09V9P2LD0djw+Pfz0N0Dr+OnLJmiNLn5bsBX09G0j25bLskFf/22zYyne5xY6f5Uufjugbfr0t2QXtE4XvwyJLn4bkpWMZAH49c9IzknW0tuLGnfoc/+a5H6DvKVYn5EMffo7kgdt0sWvAFuhn/qKoF36+uek9I+ipbUT4yacnxg36/Tr/rJvKFC29HY8npbHn/qkDNqmi18FtkI/9VVBWfr6l6TmpGop1rd4G/Tr/toE6pbennd+iT79vNRBO/SHHnVpQOr0pz670gQV6OtfldY/mpZifYFZ19PFb1Na0p5Qy9LaM4/X6NMvSnuJOpZ/yqDFzzZDaNPFLytdDv/c35QeaJMufr2EvqW343EXz2/Sp1+RvgwsxfoC0fpgPIsWP9sIoU8Xv5wMQVX6+ndkBNqiP/Ed22bGlimjqebHuvTp12XPjC1jtsbbU8zPo8XPNkMY08WvIPugOn39+3IA2qZ/53MoB5ZifcF4IFo/wF/3y5EcWor1BaL1wXiRLn5Hcuy5RId08SvJMYd/4zOSE9AO/XXXqW3m1HIZxvTpt+XMnFqm+Bxvx+NpZfqHTidyLmfOnFuK9UXi9Sf0L73Kcg7q0Nf/QC4odPHblUvQAX36PblKuLQU75t/vqnQxe9CruTaMsHmBHxusP6CLn5VuQb16Ot/LDegXbr43YKO6dMfyN0v3Fp6OxyfUJUufjdyZ+4trZ0Yj8Trb+niV0ts+MFSrG/xhvT1P5MHUIb+je8j6Bz/0EOeQHW6+D3K8wSeLBNsjrWD8Ue6+DXkeUYvltaeZj3/qc+lvICy9A+dXkFX9OnvyxuoSRe/V3mfwpultWea/0oXv6a8Bz4sE2zORHwN/6nPjXyAcvQPnT5Bt/TpH8oXqE0Xvw/5nsGXpbfDcfMh6/TXXT//+LZMsDneToxHbO4Rff3v4z8wHp+Wrc2Rf/8fKrJDJf0nqJwAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-size: contain;
  background-origin: border-box;
  background-position: 0% 0%;
  animation: loading 20s linear infinite;
  pointer-events: none;
}
