.headline {
  color: #282828;
}

.headline__hero {
  text-align: center;
  font-weight: 400;
  margin-bottom: 2rem;
  font-family: 'Luna', 'Arial', sans-serif;
  font-size: 1.475rem;
}

@media (min-width: 768px) {
  .headline__hero {
    font-size: 1.875rem;
  }
}

.headline__title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Bariol', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}

.headline__subheading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.25rem;
}

.headline--gray {
  color: #c6c6c6;
}

.headline--center {
  text-align: center;
}
