.checkout-steps__link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.stepper__hr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.checkout-steps__hint {
  text-transform: uppercase;
  color: #c6c6c6;
  font-size: 0.75rem;
  margin: 0 0 0.125rem;
  font-weight: normal;
}

.checkout-steps__title {
  margin: 0;
}

.checkout-steps__container {
  display: flex;
}

.checkout-steps__step {
  display: flex;
  align-items: center;
  flex: 1;
}

@media (max-width: 768px) {
  .checkout-steps__step {
    display: flex;
    align-items: center;
    flex: 1;
  }
}

.checkout-steps__text {
  margin-left: 1rem;
}

.checkout__icon {
  display: block;
  color: #c6c6c6;
}

.checkout__icon--completed {
  color: #f05a23;
}

.checkout__icon--active {
  color: #f05a23;
}

.checkout__icon-check {
  fill: #c6c6c6;
}

.checkout__icon-check--completed {
  fill: white;
}

.checkout__icon-check--active {
  fill: #f05a23;
}

.checkout__icon-circle {
  stroke-width: 2px;
  stroke: #c6c6c6;
  fill: white;
}

.checkout__icon-circle--completed {
  stroke-width: 2px;
  fill: #f05a23;
}

.checkout__icon-circle--active {
  stroke-width: 2px;
  stroke: #f05a23;
  fill: white;
}

.checkout-steps__back {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.checkout-steps__back-link a {
  font-weight: bold;
  text-decoration: none;
}

.checkout-steps__back-link,
.checkout-steps__back-link a {
  color: #f05a23;
}

.checkout-steps__back-link--disabled,
.checkout-steps__back-link--disabled a {
  color: #c6c6c6;
}
