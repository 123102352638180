.dialog {
}

.dialog__header {
  padding: 1.5rem 1.5rem 0;
}

.dialog__hr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .dialog__header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dialog__content {
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.dialog__actions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

@media (min-width: 768px) {
  .dialog__actions {
    justify-content: flex-end;
    flex-direction: row;
  }

  .dialog__actions > button + button {
    margin-left: 0.5rem;
  }
}

.dialog__title {
  font-size: 24px;
}

.dialog__title--bold {
  font-weight: bold;
}

.dialog__text {
  margin-top: 0;
  margin-bottom: 0;
}
