.checkoutTitle {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Bariol', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .checkoutTitle {
    font-size: 1.25rem;
  }
}

.checkoutTitle--gray {
  color: #c6c6c6;
}
