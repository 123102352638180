.card {
  display: flex;
  align-items: center;
}

.card__image {
  border-radius: 4px;
  max-width: 100%;
  display: block;
}

.card__title {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5rem;
  font-weight: bold;
}
