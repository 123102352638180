.checkoutSelectBox {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 0;
  transform: scaleY(0);
  transform-origin: left;
}

.checkoutSelectBox--visible {
  height: auto;
  transform: scaleY(1);
}

.checkoutSelectBox__title {
  margin: 0;
}

.checkoutSelectBox__item {
  flex: 1 0 10rem;
  background-color: #eee;
  padding: 1rem;
  margin: 0.5rem;
}

.checkoutSelectBox__item:hover {
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: white;
  background: #f05a23;
  cursor: pointer;
}

.checkoutSelectBox__toggle {
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.checkoutSelectBox__toggle--expandOpen {
  transform: rotate(180deg);
}

.checkoutSelectBox__header {
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkoutSelectBox__header-title {
}

.confirmationBox {
  margin-bottom: 1.5rem;
}

.confirmationBox__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirmationBox__title {
  font-weight: bold;
  font-size: 1.25rem;
}

.confirmationBox__action {
}

.confirmationBox__content {
}

.confirmationBox__text {
  margin: 0;
}

.checkout__check-container {
  padding-top: 1rem;
}

.checkout__check {
  padding-bottom: 0.3rem;
  text-align: center;
}
