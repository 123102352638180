.paper {
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  padding: 1rem;
}

.paper--wide {
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.15);
}

.paper--gutterBottom {
  margin-bottom: 1rem;
}

.paper--noGap {
  padding: 0;
}

@media (min-width: 768px) {
  .paper--slim {
    padding: 0.5rem 1.5rem;
  }

  .paper--medium {
    padding: 2rem;
  }

  .paper--wide {
    padding: 3.125rem;
  }
}

@media (max-width: 767px) {
  .paper--desktopOnly {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
}
