.page-footer {
  padding: 3.75rem 0 0 0;
  background-color: #f6f6f6;
}

.page-footer__phone {
  font-size: 2.4rem;
  font-family: 'Bariol', 'Arial', sans-serif;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.6;
  letter-spacing: 2px;
  color: #282828;
  text-decoration: none;
}

.page-footer__link {
  font-size: 1.125rem;
  line-height: 1.6;
  text-decoration: none;
  color: #282828;
}

.page-footer__link--block {
  display: block;
}

.page-footer__link:hover {
  color: #f05a23;
}

.page-footer__title {
  font-family: 'Bariol', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.6;
}

.page-footer__title--gap {
  margin-bottom: 1.5rem;
}

.page-footer__fb-logo {
  display: block;
  width: 3.25rem;
}

.page-footer__copyright {
  text-align: center;
  background-color: #282828;
  color: #fff;
  margin-top: 2.5rem;
  padding: 0.9375rem;
  font-size: 0.8125rem;
}
