.form-control {
  font-family: 'Bariol', sans-serif;
  position: relative;
  display: block;
  padding: 0.75rem 1.125rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.form-control::placeholder {
  color: #c6c6c6;
}

.form-control:required {
  box-shadow: none;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control[readonly] {
  background-color: #f8f8f8;
  opacity: 1;
}

.form-control:disabled {
  background-color: #f8f8f8;
}

.form-control--border {
  border: 1px solid #c6c6c6;
  background: transparent;
}

.form-control--error {
  border-color: #f05a23;
}

.form-control--error:focus {
  border-color: #f05a23;
}

.form-control--fullWidth {
  display: block;
  width: 100%;
}

.form-control__label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-control__error {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #f05a23;
}

.form-control__text {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
