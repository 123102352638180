@font-face {
  font-family: 'Luna';
  font-style: normal;
  font-weight: normal;
  src: local('Luna'), url('./static/fonts/luna-regular.eot') format('eot');
  src: url('./static/fonts/luna-regular.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/luna-regular.woff2') format('woff2'),
    url('./static/fonts/luna-regular.woff') format('woff');
}

@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: normal;
  src: url('./static/fonts/bariol-regular.eot') format('eot');
  src: url('./static/fonts/bariol-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./static/fonts/bariol-regular.woff2') format('woff2'),
    url('./static/fonts/bariol-regular.woff') format('woff');
}

@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: bold;
  src: url('./static/fonts/bariol-bold.eot') format('eot');
  src: url('./static/fonts/bariol-bold.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/bariol-bold.woff2') format('woff2'),
    url('./static/fonts/bariol-bold.woff') format('woff');
}

@font-face {
  font-family: 'Bariol';
  font-style: normal;
  font-weight: 500;
  src: url('./static/fonts/bariol-bold.eot') format('eot');
  src: url('./static/fonts/bariol-bold.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/bariol-bold.woff2') format('woff2'),
    url('./static/fonts/bariol-bold.woff') format('woff');
}

:root {
  --main-color: #f05a23;
  --text-color: #282828;
  --text-font-family: 'Bariol', 'Arial', 'sans-serif';
  --headline-font-family: 'Luna', 'Arial', 'sans-serif';
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  overflow: auto;
  resize: vertical;
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  font-family: 'Bariol', 'Arial', sans-serif;
  background-color: white;
}

p {
  margin-top: 0;
}

.image {
  max-width: 100%;
  display: block;
}

.checkout__links {
  margin-top: 0.5rem;
}

.checkout__links-item {
  color: #282828;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.link {
  display: inline-block;
  color: #f05a23;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.link--gutter {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.link--inline {
  display: inline;
}

.link--block {
  display: block;
}

.link--disabled {
  color: #8b9898;
}

.link--disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
}

.form {
}

.form__register,
.form__login {
  margin-left: 1rem;
  margin-right: 1rem;
}

.form__title {
  margin-top: 0;
  margin-bottom: 1rem;
}

.error__text {
  color: #f05a23;
}

.page-header__logo {
  height: 100px;
  width: 100px;
}

.form__label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.form__group,
.form-control__group {
  margin-bottom: 1rem;
}
