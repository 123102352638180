.StripeElement {
  border: 1px solid #c6c6c6;
  background: transparent;
  font-family: 'Bariol', sans-serif;
  position: relative;
  display: block;
  padding: 0.901rem 1.125rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.InputElement::placeholder {
  color: #c6c6c6;
}

.StripeElement--empty {
  color: #c6c6c6;
}
